import { PortableTextBlock } from "@portabletext/types"

import { HttpError, get } from "lib/http/http"

/**
 * Fetch avaliable regions for current handbook
 * @returns string[]
 */
export async function getRegions(): Promise<string[]> {
    const { data } = await get<string[]>(
        process.env.NEXT_PUBLIC_CONTENT_API + "/Config/Regions"
    )

    return data ?? []
}

export interface IFragment {
    title?: string
    blockContent: PortableTextBlock[]
}

/**
 * Load fragments from config-folder in CMS
 * @param path Path to the config document (without _config)
 * @returns Title and blockContent for fragment
 */
export async function getFragment(path: string): Promise<IFragment | null> {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        "/Config/Fragment?path=" +
        encodeURIComponent(path)

    const { data } = await get<IFragment>(endpoint).catch(
        (httpError: HttpError) => {
            if (httpError.response?.status == 404) return { data: null }
            else throw httpError
        }
    )

    return data
}

export interface IDictionaryWord {
    words: string[]
    description: string
}

/**
 * Get complete dictionary
 * @returns List of dictionary words with description
 */
export async function getDictionary() {
    const endpoint = process.env.NEXT_PUBLIC_CONTENT_API + "/Config/Dictionary"
    const { data } = await get<IDictionaryWord[]>(endpoint)
    return data ?? []
}

/**
 * Look up a term, fetch synonyms and description
 * @param term Term to look up
 * @returns IDictionaryWord with synonyms and description
 */
export async function getDictionaryTerm(
    term: string | undefined
): Promise<IDictionaryWord | null> {
    if (!term) {
        return null
    }

    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API + `/Config/Dictionary/${term}`
    const { data } = await get<IDictionaryWord>(endpoint, undefined).catch(
        (httpError: HttpError) => {
            if (httpError.response?.status == 404) return { data: null }
            else throw httpError
        }
    )
    return data
}
