import ConfirmUserDetailsForm from "components/ConfirmUserDetails/ConfirmUserDetailsForm";
import { Icon } from "components/Icon";
import { t } from "locales";
import IconButton from "@mui/material/IconButton";
import { useGetProfile } from "data/customerManager/hooks/profile.hooks";
import { ProfileTabType, StyledDefaultHeader } from "./ProfileUser";
export default function UserDetailsTab({
  changeTab
}: {
  changeTab: (tab: ProfileTabType) => () => void;
}) {
  const handleClose = () => {
    changeTab("default")();
  };
  const {
    data: profile
  } = useGetProfile();
  if (!profile) {
    return null;
  }
  return <>
            <StyledDefaultHeader data-sentry-element="StyledDefaultHeader" data-sentry-source-file="UserDetailsTab.tsx">
                <IconButton size="small" onClick={changeTab("default")} data-sentry-element="IconButton" data-sentry-source-file="UserDetailsTab.tsx">
                    <Icon name="arrowBack" fontSize="small" data-sentry-element="Icon" data-sentry-source-file="UserDetailsTab.tsx" />
                </IconButton>
                {t["user-details"]["confirm"]}
            </StyledDefaultHeader>
            <ConfirmUserDetailsForm profile={profile!} handleClose={handleClose} data-sentry-element="ConfirmUserDetailsForm" data-sentry-source-file="UserDetailsTab.tsx" />
        </>;
}