import { Node } from "components/Drawer/NodeDrawer";
import { Icon } from "components/Icon";
import Link from "components/Link";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
interface DrawerListItemProps {
  node: Node;
  direction: "left" | "right";
  onClose: (e: any) => void;
  onShowChildren: (e: any) => void;
  linksAtDepth: number;
  depth: number;
}
export default function NodeDrawerItem({
  node,
  direction = "left",
  onClose,
  onShowChildren,
  linksAtDepth,
  depth
}: DrawerListItemProps) {
  const hasChildNodes = !!node.nodes?.length;
  const button = depth >= linksAtDepth || !hasChildNodes ? <StyledLink button fullWidth href={node.shortcut ?? node.url} disabled={node.disabled}>
                {node.icon ? <StyledIcon fontSize="large" name={node.icon} /> : null}
                {node.name}
            </StyledLink> : <NodeButton fullWidth disabled={node.disabled} onClick={onShowChildren}>
                {node.name}
            </NodeButton>;
  return <Grid container alignItems="center" justifyContent="space-between" onClick={onClose} data-sentry-element="Grid" data-sentry-component="NodeDrawerItem" data-sentry-source-file="NodeDrawerItem.tsx">
            {hasChildNodes && direction === "right" ? <NodeIconButton onClick={onShowChildren}>
                    <Icon name="chevronLeft" />
                </NodeIconButton> : null}
            <Grid item xs data-sentry-element="Grid" data-sentry-source-file="NodeDrawerItem.tsx">
                {button}
            </Grid>
            {hasChildNodes && direction === "left" ? <NodeIconButton onClick={onShowChildren}>
                    <Icon name="chevronRight" />
                </NodeIconButton> : null}
        </Grid>;
}
const NodeButton = styled(Button)(({
  theme
}) => ({
  borderRadius: 0,
  fontSize: theme.typography.body1.fontSize,
  padding: theme.spacing(1.5, 1),
  display: "block",
  paddingLeft: theme.spacing(2),
  textAlign: "left",
  fontWeight: 700,
  "&:hover": {
    textDecoration: "none"
  }
}));
const NodeIconButton = styled(Button)(() => ({
  borderRadius: 0,
  justifyContent: "flex-start",
  minWidth: 48,
  padding: 12
}));
const StyledLink = styled(Link)(({
  theme
}) => ({
  color: "red",
  borderRadius: 0,
  justifyContent: "flex-start",
  padding: "12px 16px",
  fontSize: theme.typography.body1.fontSize,
  "&:hover": {
    textDecoration: "none"
  }
}));
const StyledIcon = styled(Icon)(({
  theme
}) => ({
  marginRight: theme.spacing(2)
}));