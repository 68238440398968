import * as React from "react";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import components from "calculators/dynamicComponents";
import { CalculatorBaseProps } from "calculators/shared";
import { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
type Components = typeof components;
type CalculatorId = keyof Components;
type CalculatorProps = CalculatorBaseProps & {
  id: CalculatorId;
};
type CalculatorSerializerProps = PortableTextTypeComponentProps<CalculatorProps>;
export function Calculator(props: CalculatorProps) {
  const [StateCalculator, setCalculator] = useState<Components[CalculatorId]>();
  useEffect(() => {
    const getCalculator = async () => {
      const CalculatorComponent = components[props.id];
      if (CalculatorComponent) {
        setCalculator(() => CalculatorComponent);
        return;
      }
    };
    getCalculator();
  }, [props.id]);
  if (StateCalculator) {
    return <StateCalculator {...props} />;
  }
  return <Skeleton data-sentry-element="Skeleton" data-sentry-component="Calculator" data-sentry-source-file="Calculator.tsx" />;
}
export default function CalculatorSerializer({
  value
}: CalculatorSerializerProps) {
  return <Calculator {...value} data-sentry-element="Calculator" data-sentry-component="CalculatorSerializer" data-sentry-source-file="Calculator.tsx" />;
}

//uncomment when new calcs are approved for use , and remove when all calculators are migrated

// const newCalculators = [
//     { id: "Barthel", oldId: "barthel" },
//     { id: "BodyMassIndex", oldId: "bodymassindex" },
//     { id: "DrugCalculatorDriversLicense", oldId: "drugsdriverlicence" },
//     { id: "CHA2DS2-VASc", oldId: "cha2ds2vasc" },
//     { id: "WaistHipIndex", oldId: "waisthipindex" },
//     { id: "IPSS", oldId: "ipss" },
//     { id: "NORRISK2", oldId: "norrisk2" },
//     { id: "Pefnomogram", oldId: "pefnomogram" },
//     { id: "Sukk-s", oldId: "sukks" }
// ]

//if the calculator is in the new calculators list, return the new calculator id

// const useNewCalculator = (id: string) => {
//     let calculatorId: undefined | string
//     newCalculators.map(calc => {
//         if (calc.oldId === id) calculatorId = calc.id
//     })
//     return calculatorId
// }