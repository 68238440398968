import FooterItem from "components/Layout/Footer/FooterCard";
import useIntersection from "hooks/useIntersection";
import { RefObject, useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import { useGetFooterCards } from "data/contentData/hooks/content.hooks";
export default function Footer() {
  const ref = useRef<HTMLDivElement>(null);
  const a = useIntersection(ref as RefObject<HTMLElement>, {
    rootMargin: "0px",
    threshold: 0.1
  });
  const [soonInView, setSoonInView] = useState(false);
  const isIntersecting = a?.isIntersecting;
  useEffect(() => {
    if (isIntersecting && !soonInView) {
      setSoonInView(true);
    }
  }, [soonInView, isIntersecting]);
  const {
    data: footer
  } = useGetFooterCards({
    enabled: soonInView
  });
  const content = footer ? footer?.map(card => <FooterItem key={card.contentId} {...card} />) : <FooterSkeleton />;
  return <StyledFooter className="no-print" data-sentry-element="StyledFooter" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
            <StyledRefDiv ref={ref} data-sentry-element="StyledRefDiv" data-sentry-source-file="index.tsx" />
            <StyledContainer maxWidth="lg" data-sentry-element="StyledContainer" data-sentry-source-file="index.tsx">
                <Grid container spacing={4} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                    {content}
                </Grid>
            </StyledContainer>
        </StyledFooter>;
}
const StyledFooter = styled("footer")(({
  theme
}) => ({
  position: "relative",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  minHeight: theme.constants.height.footerMin,
  padding: theme.spacing(4, 0)
}));
const StyledRefDiv = styled("div")({
  position: "absolute",
  top: "-800px",
  width: 0,
  height: 0
});
const StyledContainer = styled(Container)(({
  theme
}) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));
function FooterSkeleton() {
  return <Grid container spacing={4} data-sentry-element="Grid" data-sentry-component="FooterSkeleton" data-sentry-source-file="index.tsx">
            <Grid item md={4} xs={12} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Skeleton width={"100%"} height={400} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
            </Grid>
            <Grid item md={4} xs={12} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Skeleton width={"100%"} height={400} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
            </Grid>
            <Grid item md={4} xs={12} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                <Skeleton width={"100%"} height={400} data-sentry-element="Skeleton" data-sentry-source-file="index.tsx" />
            </Grid>
        </Grid>;
}