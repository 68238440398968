import CollapsibleButton from "components/CollapsibleButton";
import { Icon } from "components/Icon";
import { t } from "locales";
import { styled } from "@mui/material/styles";
import { signIn } from "auth/signIn";
export function LoginButton() {
  const handleOnClick = () => {
    signIn();
  };
  return <StyledCollapsibleButton icon={<Icon name="accountCircle" />} onClick={handleOnClick} data-sentry-element="StyledCollapsibleButton" data-sentry-component="LoginButton" data-sentry-source-file="LoginButton.tsx">
            {t["profile"]["logged-out"]["login-button"]}
        </StyledCollapsibleButton>;
}
const StyledCollapsibleButton = styled(CollapsibleButton)(({
  theme
}) => ({
  color: theme.palette.common.white
}));