import { t } from "locales";
import { JSX, ReactNode, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export default function CollapsibleList({
  children,
  title,
  collapsed
}: {
  children: ReactNode;
  title: JSX.Element;
  collapsed?: boolean;
}) {
  const [open, setOpen] = useState(!collapsed);
  const isPrinting = useMediaQuery("print");
  useEffect(() => {
    setOpen(isPrinting);
  }, [isPrinting]);
  return <ul data-sentry-component="CollapsibleList" data-sentry-source-file="CollapsibleList.tsx">
            <li>
                <Toggler data-sentry-element="Toggler" data-sentry-source-file="CollapsibleList.tsx">
                    {title}
                    <StyledButton color="secondary" onClick={() => setOpen(prev => !prev)} size="small" data-sentry-element="StyledButton" data-sentry-source-file="CollapsibleList.tsx">
                        {t["show-more"]}
                    </StyledButton>
                </Toggler>
                <Collapse in={open} data-sentry-element="Collapse" data-sentry-source-file="CollapsibleList.tsx">{children}</Collapse>
            </li>
        </ul>;
}
const Toggler = styled("div")(({
  theme
}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  gap: theme.spacing(2),
  "& > p": {
    margin: 0
  }
}));
const StyledButton = styled(Button)(({
  theme
}) => ({
  whiteSpace: "nowrap",
  padding: theme.spacing(0.25, 0.5),
  "& @media print": {
    display: "none!important"
  }
}));