import { post } from "lib/http/http"

// GROUP_IDS<ZendeskId, TranslationId>
export const GROUP_IDS = {
    "114095479833": "invoice",
    "114095479853": "order",
    "114095479873": "price-quote",
    "114095479893": "editorial-feedback",
    "114095479953": "general",
    "114095483874": "forgotten-password",
    "114095483914": "cancellation"
} as const

export interface ITicket {
    name: string
    email: string
    groupId: keyof typeof GROUP_IDS
    subject: string
    body: string
    url: string
}

/**
 * Create a ticket ("feedback") for Zendesk
 * @param ticket Ticket to send
 * @param token Access token
 */
export async function createTicket(
    ticket: ITicket,
    token: string | null | undefined
) {
    await post(
        process.env.NEXT_PUBLIC_SUPPORTMANAGER_API + "/ticket",
        JSON.stringify(ticket),
        token
    )
}
