import { useRouter } from "next/router"
import { useMemo } from "react"

/** Returns the path, without hash and query */
export default function usePath() {
    const router = useRouter()
    return useMemo(
        () => new URL(router.asPath, "http://n").pathname,
        [router.asPath]
    )
}
