import { useQuery } from "react-query"
import { OptionsType } from "types/shared.types"

import {
    IDictionaryWord,
    getDictionaryTerm,
    getRegions
} from "data/contentData/api/config"

export enum ConfigCacheKeys {
    BANNERS = "banners",
    REGIONS = "regions",
    DICTIONARY = "dictionary"
}

export function useGetRegions(options?: OptionsType<string[]>) {
    return useQuery<string[], Error>(
        [ConfigCacheKeys.REGIONS],
        async () => {
            return await getRegions()
        },
        {
            staleTime: 1000 * 60 * 60 * 8, // 8 hours
            ...options
        }
    )
}

export function useGetDictionaryTerm(
    term: string | undefined,
    options?: OptionsType<IDictionaryWord | null>
) {
    return useQuery<IDictionaryWord | null, Error>(
        [ConfigCacheKeys.DICTIONARY, term],
        async () => await getDictionaryTerm(term),
        {
            ...options
        }
    )
}
