import * as React from "react"

/**
 * Listens for changes in the url hash and executes a callback when changed
 * @param hashChangedCallback
 */
export default function useHashChange(
    hashChangedCallback: (hash: string) => void
) {
    const cbMemo = React.useMemo(
        () => hashChangedCallback,
        [hashChangedCallback]
    )

    React.useEffect(() => {
        const handleHashChange = (event: HashChangeEvent) => {
            const hash = event.newURL.split("#")[1]
            if (hash) {
                cbMemo(hash)
            }
        }
        window.addEventListener("hashchange", handleHashChange)

        return () => window.removeEventListener("hashchange", handleHashChange)
    }, [cbMemo])
}
