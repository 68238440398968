import { externalDialog } from "config/config";
import { t } from "locales";
import { ChangeEvent } from "react";
import Button from "@mui/material/Button";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { isLocalStorageSupported } from "lib/storage/is-localstorage-supported";
import { extractHostName } from "lib/url";
interface ExternalDialogProps {
  onClose: MuiButtonProps["onClick"];
  href: string;
}

/**
 * Get translated content of external link
 * @param url url of external link
 * @returns translated content of external link
 */
function getContent(url: string) {
  const key = extractHostName(url);
  if (!externalDialog) return "";
  const external = externalDialog as any;
  if (external[key]) return external[key];
  return externalDialog.default;
}
export default function ExternalDialog({
  onClose,
  href
}: ExternalDialogProps) {
  const handleChange = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isLocalStorageSupported()) {
      localStorage.setItem("external-link-popup-disabled", checked.toString());
    }
  };
  return <Dialog open aria-labelledby="draggable-dialog-title" onClose={onClose} data-sentry-element="Dialog" data-sentry-component="ExternalDialog" data-sentry-source-file="ExternalDialog.tsx">
            <DialogTitle fontSize={"h3.fontSize"} id="draggable-dialog-title" data-sentry-element="DialogTitle" data-sentry-source-file="ExternalDialog.tsx">
                {t["external-link"]["title"]}
            </DialogTitle>
            <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="ExternalDialog.tsx">
                <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="ExternalDialog.tsx">{getContent(href)}</Typography>
                <StyledLink href={href} rel="noopener noreferrer" target="_blank" data-sentry-element="StyledLink" data-sentry-source-file="ExternalDialog.tsx">
                    {href}
                </StyledLink>
            </DialogContent>
            <StyledDialogActions data-sentry-element="StyledDialogActions" data-sentry-source-file="ExternalDialog.tsx">
                <FormControlLabel control={<Checkbox color="primary" name="external" onChange={handleChange} />} label={<Typography variant="body2">
                            {t["external-link"]["checkbox-label"]}
                        </Typography>} data-sentry-element="FormControlLabel" data-sentry-source-file="ExternalDialog.tsx" />
                <Button color="primary" variant="outlined" onClick={onClose} data-sentry-element="Button" data-sentry-source-file="ExternalDialog.tsx">
                    {t["external-link"]["close"]}
                </Button>
            </StyledDialogActions>
        </Dialog>;
}
const StyledLink = styled(MuiLink)(({
  theme
}) => ({
  display: "block",
  marginTop: theme.spacing(2),
  wordBreak: "break-all",
  color: theme.palette.link.light
}));
const StyledDialogActions = styled(DialogActions)(({
  theme
}) => ({
  padding: theme.spacing(2)
}));