import { currentConfig } from "auth";
import { Icon } from "components/Icon";
import Link from "components/Link";
import { LogoutButton } from "components/ProfilePopover/LogoutButton";
import { ProfileTabType } from "components/ProfilePopover/User/ProfileUser";
import Avatar from "components/UI/Avatar";
import { Badge } from "components/UI/Badge";
import { general } from "config/config";
import useUser from "hooks/useUser";
import { t } from "locales";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useGetNotifications } from "data/contentData/hooks/notification.hooks";
import { isAdmin } from "auth/authorization";
import { nameFragments } from "lib/name-fragments";
import theme from "lib/theme";
export function DefaultTab({
  changeTab
}: {
  changeTab: (tab: ProfileTabType) => () => void;
}) {
  const {
    data: notifications
  } = useGetNotifications();
  const unreadNotifications = notifications ? notifications.length : 0;
  const showList = general.enableRegionalContent || general.enableDictionary || general.enableNotifications;
  return <>
            <Header data-sentry-element="Header" data-sentry-source-file="DefaultTab.tsx" />

            {showList && <StyledList>
                    {(general.enableRegionalContent || general.enableDictionary) && <ListItemButton onClick={changeTab("settings")}>
                            <ListItemText primary={t.profile.settings} />
                            <StyledIcon name="arrowForwardIos" />
                        </ListItemButton>}
                    {general.enableNotifications && <ListItemButton onClick={changeTab("notifications")}>
                            <ListItemText>
                                {t.profile.notifications}
                                {unreadNotifications > 0 && <Badge color={theme.palette.common.white} background={theme.palette.primary.light}>
                                        {unreadNotifications}
                                    </Badge>}
                            </ListItemText>
                            <StyledIcon name="arrowForwardIos" />
                        </ListItemButton>}
                    {general.enableConfirmUserDetails && <ListItemButton onClick={changeTab("confirmUserDetails")}>
                            <ListItemText>
                                {t["user-details"]["confirm"]}
                            </ListItemText>
                            <StyledIcon name="arrowForwardIos" />
                        </ListItemButton>}
                </StyledList>}

            <LogoutButton data-sentry-element="LogoutButton" data-sentry-source-file="DefaultTab.tsx" />
        </>;
}
const StyledList = styled(List)(() => ({
  padding: 0
}));
const StyledIcon = styled(Icon)(() => ({
  fontSize: "12px"
}));
function Header() {
  const {
    user
  } = useUser();
  if (!user) {
    return null;
  }
  const userRoles = user?.roles || [];
  const {
    initials,
    firstName,
    restOfName
  } = nameFragments(user.name ?? "");
  return <StyledHeaderWrapper data-sentry-element="StyledHeaderWrapper" data-sentry-component="Header" data-sentry-source-file="DefaultTab.tsx">
            <Avatar color={theme.palette.common.white} background={theme.palette.primary.main} data-sentry-element="Avatar" data-sentry-source-file="DefaultTab.tsx">
                {initials}
            </Avatar>
            <StyledHeaderContent data-sentry-element="StyledHeaderContent" data-sentry-source-file="DefaultTab.tsx">
                <StyledName data-sentry-element="StyledName" data-sentry-source-file="DefaultTab.tsx">
                    {firstName}{" "}
                    <StyledRestOfName data-sentry-element="StyledRestOfName" data-sentry-source-file="DefaultTab.tsx">{restOfName}</StyledRestOfName>
                </StyledName>
                <Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="DefaultTab.tsx">{user.email}</Typography>
                <StyledLinkWrapper data-sentry-element="StyledLinkWrapper" data-sentry-source-file="DefaultTab.tsx">
                    <StyledLink shallow color="secondary" href={currentConfig.editProfileUrl} rel="noopener noreferrer" target="_blank" data-sentry-element="StyledLink" data-sentry-source-file="DefaultTab.tsx">
                        {t.profile.edit}
                    </StyledLink>
                    {isAdmin(userRoles) && <StyledLink shallow color="secondary" href="/admin">
                            {"Admin"}
                        </StyledLink>}
                </StyledLinkWrapper>
            </StyledHeaderContent>
        </StyledHeaderWrapper>;
}
const StyledHeaderWrapper = styled("div")(() => ({
  display: "flex",
  gap: ".75rem",
  padding: theme.spacing(2, 2, 0, 2)
}));
const StyledHeaderContent = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(0.5),
  flexGrow: 1
}));
const StyledLinkWrapper = styled("div")(() => ({
  display: "flex",
  gap: theme.spacing(2)
}));
const StyledLink = styled(Link)(() => ({
  color: theme.palette.secondary.main,
  fontSize: "14px",
  fontWeight: 700
}));
const StyledName = styled("div")(() => ({
  gap: "0.5rem",
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: 700,
  justifyContent: "center"
}));
const StyledRestOfName = styled("span")(() => ({
  fontWeight: 400
}));