import { PortableTextProps, PortableTextReactComponents, PortableText as SanityPortableText } from "@portabletext/react";
import { captureException } from "@sentry/nextjs";
import Blockquote from "components/PortableText/Block/BlockQuote";
import Heading from "components/PortableText/Block/Heading";
import Anchor from "components/PortableText/Marks/Anchor";
import Link from "components/PortableText/Marks/Link";
import Quote from "components/PortableText/Marks/Quote";
import Sub from "components/PortableText/Marks/Sub";
import Sup from "components/PortableText/Marks/Sup";
import Audio from "components/PortableText/Types/Audio";
import Button from "components/PortableText/Types/Button";
import CalculatorIframe from "components/PortableText/Types/Calculator";
import EasyLMS from "components/PortableText/Types/EasyLMS";
import Image from "components/PortableText/Types/Image";
import ImageList from "components/PortableText/Types/ImageList";
import ImageWithGalleryLink from "components/PortableText/Types/ImageWithGalleryLink";
import Note from "components/PortableText/Types/Notes/Note";
import RealtimeGuideline from "components/PortableText/Types/Notes/RealtimeGuideline";
import Placeholder from "components/PortableText/Types/Placeholder";
import ReferenceInline from "components/PortableText/Types/ReferenceInline";
import Source from "components/PortableText/Types/Source";
import Table from "components/PortableText/Types/Table";
import VideoEmbed from "components/PortableText/Types/VideoEmbed";
import UnknownMark from "components/PortableText/UnknownMark";
import UnknownType from "components/PortableText/UnknownType";
interface IPortableTextProps extends PortableTextProps {
  customComponents?: Partial<PortableTextReactComponents>;
  wrapImgWithGalleryLink?: boolean;
}
export default function PortableText(props: IPortableTextProps) {
  const {
    customComponents,
    wrapImgWithGalleryLink,
    ...rest
  } = props;
  if (!rest.value) {
    return null;
  }
  return <SanityPortableText {...rest} components={{
    marks: {
      ...defaultComponents.marks,
      ...customComponents?.marks
    },
    types: {
      ...defaultComponents.types,
      ...customComponents?.types,
      image: wrapImgWithGalleryLink ? ImageWithGalleryLink : Image
    },
    block: customComponents?.block ?? defaultComponents.block,
    hardBreak: customComponents?.hardBreak ?? defaultComponents.hardBreak,
    list: customComponents?.list ?? defaultComponents.list,
    listItem: customComponents?.listItem ?? defaultComponents.listItem
  }} onMissingComponent={(message, options) => {
    if (process.env.NODE_ENV === "development") {
      // eslint-disable-next-line no-console
      console.error("###", message, options);
    } else {
      captureException(new Error(message));
    }
    return null;
  }} data-sentry-element="SanityPortableText" data-sentry-component="PortableText" data-sentry-source-file="PortableText.tsx" />;
}
const defaultComponents: Partial<PortableTextReactComponents> = {
  marks: {
    anchor: Anchor,
    link: Link,
    subscript: Sub,
    superscript: Sup,
    quote: Quote
  },
  types: {
    button: Button,
    "image-list": ImageList,
    image: Image,
    note: Note,
    "realtime-guideline": RealtimeGuideline,
    ref: ReferenceInline,
    table: Table,
    "video-embed": VideoEmbed,
    audio: Audio,
    source: Source,
    placeholder: Placeholder,
    calculator: CalculatorIframe,
    "easy-lms": EasyLMS
  },
  block: {
    h1: ({
      children
    }) => <Heading variant="h1">{children}</Heading>,
    h2: ({
      children
    }) => <Heading variant="h2">{children}</Heading>,
    h3: ({
      children
    }) => <Heading variant="h3">{children}</Heading>,
    h4: ({
      children
    }) => <Heading variant="h4">{children}</Heading>,
    h5: ({
      children
    }) => <Heading variant="h5">{children}</Heading>,
    h6: ({
      children
    }) => <Heading variant="h6">{children}</Heading>,
    blockquote: ({
      children
    }) => <Blockquote>{children}</Blockquote>
  },
  hardBreak: () => <br />,
  list: ({
    children
  }) => <ul>{children}</ul>,
  listItem: ({
    children
  }) => <li>{children}</li>,
  unknownMark: UnknownMark,
  unknownType: UnknownType
};