import { PortableTextTypeComponentProps } from "@portabletext/react";
import PortableText from "components/PortableText/PortableText";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
interface TableProps {
  caption?: string;
  rows: Array<Array<{
    blockContent: string;
    colSpan?: number;
    rowSpan?: number;
    style?: string;
  }>>;
  style?: string;
}
export default function Table({
  value
}: PortableTextTypeComponentProps<TableProps>) {
  const classes = value.style?.split(",").join(" ") ?? "";
  return <TableWrapper data-sentry-element="TableWrapper" data-sentry-component="Table" data-sentry-source-file="Table.tsx">
            <MuiTable className={classes} data-sentry-element="MuiTable" data-sentry-source-file="Table.tsx">
                {value.caption && <caption>
                        <PortableText value={JSON.parse(value.caption)} />
                    </caption>}
                <TableBody data-sentry-element="TableBody" data-sentry-source-file="Table.tsx">
                    {value.rows.map((row, rowIndex) => <TableRow
        // eslint-disable-next-line react/no-array-index-key
        key={rowIndex}>
                            {row.map(({
            blockContent,
            style,
            ...props
          }, cellIndex) => <StyledTableCell emphasized={style === "em" ? "true" : "false"}
          // eslint-disable-next-line react/no-array-index-key
          key={cellIndex} {...props}>
                                        <PortableText value={JSON.parse(blockContent)} wrapImgWithGalleryLink />
                                    </StyledTableCell>)}
                        </TableRow>)}
                </TableBody>
            </MuiTable>
        </TableWrapper>;
}
const TableWrapper = styled("div")(({
  theme
}) => ({
  overflowX: "auto",
  margin: theme.spacing(2, 0),
  "&::-webkit-scrollbar": {
    width: "4px"
  },
  "&::-webkit-scrollbar-track": {
    marginTop: "2px",
    backgroundColor: theme.palette.grey[200]
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: grey[500]
  },
  "& table": {
    margin: 0
  },
  "& caption": {
    fontSize: "14px",
    padding: theme.spacing(0, 1)
  }
}));
const StyledTableCell = styled(TableCell)<{
  emphasized: string;
}>(({
  emphasized,
  theme
}) => ({
  overflowWrap: "break-word",
  fontWeight: emphasized === "true" ? 700 : 400,
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(0, 1),
  fontSize: "14px",
  verticalAlign: "top",
  "& ul": {
    paddingLeft: theme.spacing(2)
  }
}));