import { PortableTextTypeComponentProps } from "@portabletext/react";
export interface SourceProps {
  src: string;
}
export default function Source({
  value
}: PortableTextTypeComponentProps<SourceProps>) {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio controls src={value.src} data-sentry-component="Source" data-sentry-source-file="Source.tsx" />
  );
}