import Footer from "components/Layout/Footer";
import { Header, HeaderSimple } from "components/Layout/Header/Header";
import LoadingIndicator from "components/Layout/LoadingIndicator";
import SkipNavLink from "components/SkipNav";
import { ReactNode } from "react";
interface LayoutProps {
  children: ReactNode;
  isSimple?: boolean;
}
export default function Layout({
  children,
  isSimple
}: LayoutProps) {
  return <div id="layout-root" data-sentry-component="Layout" data-sentry-source-file="index.tsx">
            <SkipNavLink data-sentry-element="SkipNavLink" data-sentry-source-file="index.tsx" />
            <LoadingIndicator data-sentry-element="LoadingIndicator" data-sentry-source-file="index.tsx" />
            {isSimple ? <HeaderSimple /> : <Header />}
            {children}
            {isSimple ? null : <Footer />}
        </div>;
}