export function isLocalStorageSupported() {
    try {
        const testKey = "__NHI_TEST_LOCALSTORAGE_KEY__"
        localStorage.setItem(testKey, testKey)
        localStorage.removeItem(testKey)
        return true
    } catch {
        return false
    }
}
