import { Icon } from "components/Icon";
import { DictionarySwitch } from "components/ProfilePopover/User/DictionarySwitch";
import { ProfileTabType, StyledDefaultHeader } from "components/ProfilePopover/User/ProfileUser";
import RegionSelect from "components/ProfilePopover/User/RegionSelect";
import { general } from "config/config";
import { t } from "locales";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
export function SettingsTab({
  changeTab
}: {
  changeTab: (tab: ProfileTabType) => () => void;
}) {
  return <>
            <StyledDefaultHeader data-sentry-element="StyledDefaultHeader" data-sentry-source-file="SettingsTab.tsx">
                <IconButton size="small" onClick={changeTab("default")} data-sentry-element="IconButton" data-sentry-source-file="SettingsTab.tsx">
                    <Icon name="arrowBack" fontSize="small" data-sentry-element="Icon" data-sentry-source-file="SettingsTab.tsx" />
                </IconButton>
                {t.profile.settings}
            </StyledDefaultHeader>
            <StyledWrapper data-sentry-element="StyledWrapper" data-sentry-source-file="SettingsTab.tsx">
                {general.enableRegionalContent && <RegionSelect />}
                {general.enableDictionary && <DictionarySwitch />}
            </StyledWrapper>
        </>;
}
const StyledWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem"
}));