import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { JSX } from "react";
interface CollapsibleButtonProps extends ButtonProps {
  icon: JSX.Element;
  children: string | null;
  active?: boolean;
}
export default function CollapsibleButton({
  children,
  icon,
  ref,
  ...props
}: CollapsibleButtonProps) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const showText = smUp && children;
  const _title = typeof children === "string" ? children : "";
  return <Button title={_title} ref={ref} color="secondary" {...props} data-sentry-element="Button" data-sentry-component="CollapsibleButton" data-sentry-source-file="CollapsibleButton.tsx">
            {icon}
            {showText && <Typography ml={1}>{children}</Typography>}
        </Button>;
}