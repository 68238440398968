import { Icon } from "components/Icon";
import Link from "components/Link";
import Divider from "@mui/material/Divider";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { IMegamenuNode } from "data/contentData/api/content";
interface MegamenuVolumesProps {
  volumes: IMegamenuNode[];
  selected: number;
  onVolumeClick(path: number): (event: any) => void;
  onCloseClick(): void;
}
export default function MegamenuVolumes(props: MegamenuVolumesProps) {
  const {
    volumes,
    selected,
    onVolumeClick,
    onCloseClick
  } = props;
  const listVolumes = volumes.filter(p => p.type === "List");
  const nonListVolumes = volumes.filter(p => p.type !== "List");
  return <>
            <List disablePadding data-sentry-element="List" data-sentry-source-file="MegamenuVolumes.tsx">
                {listVolumes.map((volume, index) => {
        const _selected = index === selected;
        return <li key={volume.url}>
                            <StyledListItem disableRipple onClick={onVolumeClick(index)} selected={_selected}>
                                <Hidden smDown>
                                    <ListItemIcon>
                                        <StyledIcon isselected={_selected.toString()} name={volume.icon ?? ""} />
                                    </ListItemIcon>
                                </Hidden>
                                <ListItemText primary={<Typography fontWeight={700} component="span">
                                            {volume.name}
                                        </Typography>} primaryTypographyProps={{
              noWrap: true
            }} />
                            </StyledListItem>
                        </li>;
      })}
            </List>
            <Divider data-sentry-element="Divider" data-sentry-source-file="MegamenuVolumes.tsx" />
            <List disablePadding data-sentry-element="List" data-sentry-source-file="MegamenuVolumes.tsx">
                {nonListVolumes.map(volume => {
        return <ListItem key={volume.url}>
                            <Hidden smDown>
                                <ListItemIcon>
                                    <Icon name={"null"} color="inherit" />
                                </ListItemIcon>
                            </Hidden>
                            <Typography component="span" variant="body1">
                                <StyledLink href={volume.url} onClick={onCloseClick}>
                                    {volume.name}
                                </StyledLink>
                            </Typography>
                        </ListItem>;
      })}
            </List>
        </>;
}
const StyledListItem = styled(ListItemButton)(({
  theme
}) => ({
  backgroundColor: "transparent",
  color: theme.palette.common.white,
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.dark,
    "&:hover": {
      backgroundColor: theme.palette.background.default
    }
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected
  }
}));
const StyledIcon = styled(Icon)<{
  isselected: string;
}>(({
  theme,
  isselected
}) => ({
  color: isselected === "false" ? theme.palette.common.white : theme.palette.secondary.dark
}));
const StyledLink = styled(Link)(({
  theme
}) => ({
  color: theme.palette.common.white
}));