import { PortableTextMarkComponentProps } from "@portabletext/react";
import { styled } from "@mui/material/styles";
export default function Anchor({
  value
}: PortableTextMarkComponentProps) {
  if (!value || !value.id) {
    return null;
  }
  return <StyledAnchor id={value.id} data-sentry-element="StyledAnchor" data-sentry-component="Anchor" data-sentry-source-file="Anchor.tsx" />;
}
const StyledAnchor = styled("a")(({
  theme
}) => {
  const {
    breakpoints,
    constants
  } = theme;
  return {
    scrollMarginTop: `${constants.height.header + constants.height.headerXSExtra + 16}px`,
    [breakpoints.up("sm")]: {
      scrollMarginTop: `${constants.height.header + 16}`
    },
    [breakpoints.up("md")]: {
      scrollMarginTop: `${constants.height.header + constants.height.breadcrumbs + 16}px`
    } as any
  };
});