import { PortableTextTypeComponentProps } from "@portabletext/react";
import { Image as ImageComponent } from "components/Image";
import { forwardRef, JSX } from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
export interface IContentImage {
  alt?: string;
  caption?: string;
  src: string;
  title?: string;
  noZoom?: boolean;
  align?: "none" | "pull-left" | "pull-right";
  size?: "small" | "medium" | "large";
  index: number;
  width?: number;
  height?: number;
}
interface IImgContentProps {
  children: JSX.Element;
}

// NOTE:
// The tooltip needs to apply DOM event listeners to its child element.
// If the child is a custom React element, you need to make sure that it spreads its properties to the underlying DOM element.
const ImgContent = forwardRef<HTMLSpanElement, IImgContentProps>(function (props: IImgContentProps, ref) {
  return <span {...props} ref={ref}>
            {props.children}
        </span>;
});
ImgContent.displayName = "ImgContent";
type ImgProps = PortableTextTypeComponentProps<IContentImage> & {
  alt?: string;
  button?: boolean;
};
export default function Image(props: ImgProps) {
  const {
    value: {
      src,
      alt,
      title,
      caption,
      width,
      height
    },
    button
  } = props;
  let component;
  if (width && height) {
    component = <ImgContent>
                <StyledImage src={src} alt={alt || ""} width={width} height={height} />
            </ImgContent>;
  } else {
    component = <ImgContent>
                <SimpleImg nativeImg={true} src={src} alt={alt || ""} />
            </ImgContent>;
  }
  if (caption) {
    component = <ImgContent>
                <StyledFigure isbutton={button ? "true" : "false"}>
                    {component}
                    <Typography variant="caption" component="figcaption">
                        {caption}
                    </Typography>
                </StyledFigure>
            </ImgContent>;
  }
  if (title) {
    return <Tooltip title={title}>{component}</Tooltip>;
  }
  return component;
}
const StyledImage = styled(ImageComponent)(() => ({
  objectFit: "cover",
  maxWidth: "100%",
  height: "auto"
}));
const SimpleImg = styled(ImageComponent)(() => ({
  maxWidth: "100%",
  height: "auto"
}));
const StyledFigure = styled("figure")<{
  isbutton: string;
}>(({
  isbutton
}) => ({
  margin: 0,
  padding: isbutton === "true" ? 0 : undefined,
  width: isbutton === "true" ? "100%" : undefined,
  overflowWrap: "anywhere"
}));