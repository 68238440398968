import { general } from "config/config"
import useUser from "hooks/useUser"
import { useQuery, useQueryClient } from "react-query"
import { useMutation } from "react-query"
import { OptionsType } from "types/shared.types"

import {
    INotification,
    getNotifications,
    markNotificationAsRead
} from "data/contentData/api/notification"

enum NotificationCacheKeys {
    NOTIFICATIONS = "notifications"
}

export function useGetNotifications(options?: OptionsType<INotification[]>) {
    const { authenticated, accessToken, isFetching } = useUser()

    const enabled = general.enableNotifications && authenticated && !isFetching

    return useQuery<INotification[], Error>(
        [NotificationCacheKeys.NOTIFICATIONS],
        async () => await getNotifications(accessToken),
        {
            refetchInterval: 60 * 60 * 1000,
            ...options,
            enabled
        }
    )
}

export function useMarkNotificationAsRead() {
    const queryClient = useQueryClient()
    const { accessToken } = useUser()

    return useMutation(
        async ({ id }: { id: number }) =>
            await markNotificationAsRead(id, accessToken),
        {
            onMutate: async ({ id }) => {
                // Cancel outgoing queries
                await queryClient.cancelQueries([
                    NotificationCacheKeys.NOTIFICATIONS
                ])

                // Snapshot previous data
                const previousData = queryClient.getQueryData<INotification[]>([
                    NotificationCacheKeys.NOTIFICATIONS
                ])

                // Optimistic update
                if (previousData && previousData.length > 0) {
                    const newNotificationData = previousData.filter(
                        notification => notification.id !== id
                    )
                    queryClient.setQueryData(
                        [NotificationCacheKeys.NOTIFICATIONS],
                        newNotificationData
                    )
                }
                return { previousData }
            },
            onError: (_, __, context: any) => {
                // revert if error
                if (context?.previousData && context?.previousData.length > 0) {
                    queryClient.setQueryData(
                        [NotificationCacheKeys.NOTIFICATIONS],
                        context.previousData
                    )
                }
            },
            onSuccess: () => {
                queryClient.invalidateQueries(
                    NotificationCacheKeys.NOTIFICATIONS
                )
            }
        }
    )
}
