import { PortableTextMarkComponent } from "@portabletext/react";
import { captureException } from "@sentry/nextjs";
const UnknownMark: PortableTextMarkComponent<any> | undefined = props => {
  if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.error("### unknownMark: ", props);
  } else {
    captureException(new Error("unknownMark"));
  }
  return null;
};
export default UnknownMark;