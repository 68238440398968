import { OTHER_HEALTH_PERSONNEL_PROFESSION_ID, PHYSICIAN_PROFESSION_ID, STUDENT_PROFESSION_ID } from "components/ConfirmUserDetails/ConfirmUserDetails";
import { t } from "locales";
import { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import { ProfileFormType } from "data/customerManager/api/profile";
interface IHPRProps {
  form: ProfileFormType;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
export default function HPRField({
  form,
  onChange
}: IHPRProps) {
  if (![PHYSICIAN_PROFESSION_ID, STUDENT_PROFESSION_ID, OTHER_HEALTH_PERSONNEL_PROFESSION_ID].includes(form.professionId)) {
    return null;
  }
  return <TextField fullWidth id="hprNumber" label={t["user-details"]["hpr-number"]} name="hprNumber" variant="outlined" inputProps={{
    pattern: ".{6,}"
  }} value={form.hprNumber === 0 ? "" : form.hprNumber} onChange={onChange} required={form.professionId !== STUDENT_PROFESSION_ID} data-sentry-element="TextField" data-sentry-component="HPRField" data-sentry-source-file="HPRField.tsx" />;
}