import { azureAdConfig } from "auth/azureAdConfig"
import { identityServerConfig } from "auth/identityServerConfig"
import { keycloakConfig } from "auth/keyCloakConfig"
import { auth } from "config/config"
import { JWT } from "next-auth/jwt"

export interface IProduct {
    id: string
    name: string
    productId: number
    start: string
}

export type AccessTokenResponseType = {
    access_token: string
    expires_in: number
    token_type: string
}

export type AuthConfigType = {
    provider: any
    accessTokenUrl: string
    editProfileUrl: string
    endSessionUrl: string

    getServerSideAccessToken: () => Promise<AccessTokenResponseType>
    getRoles: (token: JWT) => Promise<string[]>
    getProducts: (token: JWT) => Promise<IProduct[]>
}

function getCurrentAuthConfig(): AuthConfigType {
    switch (auth.provider) {
        case "keycloak":
            return keycloakConfig
        case "azure-ad-b2c":
            return azureAdConfig
        case "identity-server4":
            return identityServerConfig
        default:
            throw new Error("Unknown auth provider...")
    }
}

const currentConfig = getCurrentAuthConfig()

export { currentConfig, azureAdConfig, identityServerConfig, keycloakConfig }
