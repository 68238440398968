/***
 * Keyset of locales
 */
const locales = {
    de: "de-DE",
    no: "nb-NO",
    se: "sv-SE",
    pl: "pl",
    fr: "fr-fr"
}

export default locales
