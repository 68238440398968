import { Router } from "next/router";
import { useEffect, useState } from "react";
import { useIsFetching } from "react-query";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
export default function LoadingIndicator() {
  const loading = useLoadingIndicator();
  if (loading) {
    return <StyledLinearProgress />;
  }
  return null;
}
const StyledLinearProgress = styled(LinearProgress)(() => ({
  marginBottom: -4,
  position: "fixed",
  top: 0,
  width: "100%",
  zIndex: 99999
}));

/**
 * Returns `true` if any async operation is being run that affects the UX.
 * For example data fetching, or route changes.
 */
function useLoadingIndicator() {
  const [routeChange, setRouteChange] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const routeChangeStartHandler = () => setRouteChange(true);
    const off = () => {
      setRouteChange(false);
      window.scrollTo(0, 0);
    };

    // @ts-ignore https://github.com/vercel/next.js/pull/26456
    Router.events.on("loading", setLoading);
    Router.events.on("routeChangeStart", routeChangeStartHandler);
    Router.events.on("routeChangeComplete", off);
    Router.events.on("routeChangeError", off);
    return () => {
      // @ts-ignore https://github.com/vercel/next.js/pull/26456
      Router.events.off("loading", setLoading);
      Router.events.off("routeChangeStart", routeChangeStartHandler);
      Router.events.off("routeChangeComplete", off);
      Router.events.off("routeChangeError", off);
    };
  }, []);
  const isFetching = useIsFetching();
  useEffect(() => {
    // @ts-ignore https://github.com/vercel/next.js/pull/26456
    Router.events.emit("loading", Boolean(isFetching > 1));
  }, [isFetching]);
  return loading || routeChange;
}