import {
    IProfession,
    getProfessions
} from "data/customerManager/api/profession"
import { get, post } from "lib/http/http"

export interface IProfile {
    guid: string
    name: string
    email: string
    postAddress: string
    zip: string
    city: string
    professionId: number
    secondaryProfessionId: number
    tertiaryProfessionId: number
    hprNumber: number
    placeOfWork: string
    studentLocation: string
    lastProfileUpdate: string | null

    professions: IProfession[]
}

/**
 * Get profile of the authenticated user
 * @param token Access token
 * @returns Profile of the authenticated user
 */
export async function getProfile(token: string | null | undefined) {
    if (!token) {
        return null
    }

    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/profile`
    const { data: profile } = await get<IProfile>(url, token)
    if (!profile) {
        return null
    }

    const professions = await getProfessions()

    const result = {
        ...profile,
        professions
    }

    // If placeOfWork is null, set to empty string
    if (result.placeOfWork === null) {
        result.placeOfWork = ""
    }

    // If professionId is invalid (only options where parent is null are valid), fix and update secondaryProfessionId
    const prof = professions?.find(p => p.id === profile?.professionId)
    if (prof && prof.parent !== null) {
        result.professionId = prof.parent
        result.secondaryProfessionId = prof.id
    }

    return result
}

export type ProfileFormType = Omit<IProfile, "professions">

/**
 *
 * @param profile
 * @param token
 */
export async function updateProfile(
    profile: ProfileFormType,
    token: string | null | undefined
) {
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/profile`
    await post(url, JSON.stringify(profile), token)
}
