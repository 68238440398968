import React from "react"

/**
 * Returns a positive integer if Ctrl+F was pressed.
 * This way each Ctrl+F will trigger a change.
 *
 * The reason for returning an integer instead of a boolean
 * is that we cannot detect when to reset the state, so we keep incrementing.
 */
export function useDetectOnPageSearch() {
    const [pressed, setPressed] = React.useState(0)
    React.useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.key === "f") {
                setPressed(p => p + 1)
            }
        }

        window.addEventListener("keydown", handler)
        return () => {
            window.removeEventListener("keydown", handler)
        }
    }, [setPressed])

    return pressed
}
