import { Node } from "components/Drawer/NodeDrawer"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

interface Item {
    url: string
    node?: HTMLElement | null
}

function offsetTop(el?: HTMLElement | null) {
    if (el) {
        const rect = el.getBoundingClientRect(),
            scrollTop = window.pageYOffset || document.documentElement.scrollTop
        return rect.top + scrollTop
    }

    return 0
}

function flattenNodes(nodes: Node[]) {
    return nodes.flatMap(({ nodes: mappedNodes = [], url }) => {
        return [{ url }, ...mappedNodes.map(({ url: URL }) => ({ url: URL }))]
    })
}

function getItemsClient(items: Item[]) {
    const result = items.map(({ url }) => ({
        url,
        node: document.getElementById(url.replace("#", ""))
    }))

    return result.filter(p => p.node !== null)
}

function throttle(callback: () => void, delay: number) {
    let timeout: any = null
    const internalThrottle = function (this: any, ...args: any[]) {
        if (!timeout) {
            timeout = setTimeout(() => {
                callback.call<any, any[], unknown>(this, ...args)
                timeout = null
            }, delay)
        }
    }
    internalThrottle.cancel = () => clearTimeout(timeout)
    return internalThrottle
}

export function useToggleOnScroll(buffer = 100, delay = 200) {
    const [show, setShow] = useState(true)

    useEffect(() => {
        let prevY: number | undefined = undefined

        const handleScroll = throttle(() => {
            const y = window.pageYOffset
            if (prevY === undefined) {
                prevY = y
            }

            if (y > prevY + buffer) {
                setShow(false)
                prevY = y
            } else if (y + buffer < prevY) {
                setShow(true)
                prevY = y
            }
        }, delay)

        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [buffer, delay])

    return show
}

function useThrottledOnScroll(
    callback: (() => void) | undefined,
    delay: number
) {
    const throttledCallback = useMemo(
        () => (callback ? throttle(callback, delay) : undefined),
        [callback, delay]
    )

    useEffect(() => {
        if (!throttledCallback) {
            return
        }

        window.addEventListener("scroll", throttledCallback)
        return () => {
            window.removeEventListener("scroll", throttledCallback)
            throttledCallback.cancel()
        }
    }, [throttledCallback])
}

function useScrollSpy(nodes: Node[]) {
    const itemsWithNodeRef = useRef<Item[]>([])
    useEffect(() => {
        itemsWithNodeRef.current = getItemsClient(flattenNodes(nodes))
    }, [nodes])

    const [activeState, setActiveState] = useState<string | undefined>()

    const findActive = useCallback(() => {
        let active

        for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
            // No hash if we're near the top of the page
            if (document.documentElement.scrollTop < 100) {
                active = { url: undefined }
                break
            }

            const item = itemsWithNodeRef.current[i]

            if (process.env.NODE_ENV !== "production") {
                if (!item.node) {
                    console.error(
                        `Missing node on the item ${JSON.stringify(
                            item,
                            null,
                            2
                        )}`
                    )
                }
            }

            const scrollTopModified =
                document.documentElement.scrollTop +
                64 + // header
                44 + // breadcrumb
                16 + // padding ?
                8 // for good measure!

            if (offsetTop(item.node) < scrollTopModified) {
                active = item
                break
            }
        }

        if (active && activeState !== active.url) {
            setActiveState(active.url)
        }
    }, [activeState])

    useThrottledOnScroll(nodes.length > 0 ? findActive : undefined, 166)

    return activeState
}

export function useAddActiveToNodes(nodes: Node[]) {
    const activeElement = useScrollSpy(nodes ?? []) ?? ""
    const _nodes = useMemo<Node[]>(() => {
        return (
            nodes?.map(({ nodes: innerNodes, ...node }) => {
                return {
                    ...node,
                    active: activeElement?.includes(node.url),
                    nodes: innerNodes?.map(innerNode => ({
                        ...innerNode,
                        active: activeElement?.endsWith(innerNode.url)
                    }))
                }
            }) ?? []
        )
    }, [activeElement, nodes])

    return { nodes: _nodes, activeElement }
}
