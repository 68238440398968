export function extractHostName(url: string) {
    const trimmedUrl = url.trim()

    try {
        const domain = new URL(
            trimmedUrl.startsWith("http") ? trimmedUrl : `https://${trimmedUrl}`
        )
        return domain.hostname.replace("www.", "")
    } catch {}

    return url
}
