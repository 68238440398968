import { PropsInButtonNotCollidingWithLinkProps as ButtonLinkUnionProps } from "components/Link/Link";
import { forwardRef } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
type ButtonLinkProps = ButtonLinkUnionProps & {
  ref?: any;
};
const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(function ButtonLink(props, ref) {
  return <StyledButtonLink {...props} ref={ref} component="a" />;
});
export default ButtonLink;
const StyledButtonLink = styled(Button as any)<{
  component: string;
}>({
  textTransform: "none",
  "&:hover": {
    textDecoration: "none"
  }
});