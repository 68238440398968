import { get, post } from "lib/http/http"

export interface INotification {
    id: number
    createdUtc: Date
    subject: string
    body: string
}

/**
 * Get notifications for logged in user
 * @param token Access token
 * @returns List of notifications for logged in user
 */
export async function getNotifications(token: string | null | undefined) {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API + "/Notification/GetUnread"
    const { data } = await get<INotification[]>(endpoint, token)
    return data ?? []
}

/**
 * Mark message as seen
 * @param id Id of the message
 * @param token Access token
 * @returns NOTHING!
 */
export async function markNotificationAsRead(
    id: number,
    token: string | null | undefined
) {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API + "/Notification/MarkAsSeen"
    await post(endpoint, `${id}`, token)
}
