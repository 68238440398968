import { PortableTextMarkComponent } from "@portabletext/react";
import { Image } from "components/Image";
import Link from "components/Link";
import PortableText from "components/PortableText/PortableText";
import Grid, { GridSize } from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { IFooterCard } from "data/contentData/api/content";
export default function FooterCard({
  title,
  image,
  columns,
  blockContent
}: IFooterCard) {
  return <Grid item textAlign={columns === 12 ? "center" : "inherit"} md={columns as GridSize} xs={12} data-sentry-element="Grid" data-sentry-component="FooterCard" data-sentry-source-file="FooterCard.tsx">
            {image ? <StyledImage nativeImg alt={title ?? ""} src={image.src} /> : null}

            {blockContent && <PortableText value={blockContent} customComponents={{
      marks: {
        link: FooterLink
      }
    }} />}
        </Grid>;
}
const StyledImage = styled(Image)(() => ({
  maxWidth: "100%",
  objectFit: "contain",
  overflow: "hidden"
}));
const FooterLink: PortableTextMarkComponent = ({
  children,
  value
}) => {
  const {
    href,
    target
  } = value;
  return <Link underline="always" color="link.dark" href={href} target={target} data-sentry-element="Link" data-sentry-component="FooterLink" data-sentry-source-file="FooterCard.tsx">
            {children}
        </Link>;
};