//eslint-disable-next-line
import NextImage, { ImageProps } from "next/image";
interface IImageProps extends Omit<ImageProps, "alt"> {
  alt: string | undefined;
  nativeImg?: boolean;
}
export function Image({
  src,
  alt,
  width,
  height,
  nativeImg,
  loading,
  ...rest
}: IImageProps) {
  if (width && height) {
    return <NextImage id="next-future-image-wh" src={src} alt={alt ?? ""} width={width} height={height} loading={loading} {...rest} />;
  } else if (nativeImg) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img id="native-img" src={src as string} alt={alt} loading={loading} {...rest} />
    );
  }
  return <NextImage id="next-future-image" src={src} alt={alt ?? ""} loading={loading} {...rest} data-sentry-element="NextImage" data-sentry-component="Image" data-sentry-source-file="Image.tsx" />;
}