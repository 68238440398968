import { Icon } from "components/Icon";
import { ReactElement, ReactNode } from "react";
import MUIDrawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import Slide from "@mui/material/Slide";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
interface IDrawer {
  header?: ReactElement | null;
  open: boolean;
  onClose: () => void;
  position?: "left" | "right";
  children?: ReactNode;
}
export function Drawer({
  header = null,
  open,
  onClose,
  position = "left",
  children
}: IDrawer) {
  return <>
            <StyledDrawer disablePortal anchor={position} onClose={onClose} open={open} data-sentry-element="StyledDrawer" data-sentry-source-file="Drawer.tsx">
                {header && <DrawerHeader>{header}</DrawerHeader>}
                <DrawerContent header={Boolean(header).toString()} data-sentry-element="DrawerContent" data-sentry-source-file="Drawer.tsx">
                    {children}
                </DrawerContent>
            </StyledDrawer>
            <CloseDrawerButton position={position} direction={position} in={open} data-sentry-element="CloseDrawerButton" data-sentry-source-file="Drawer.tsx">
                <Fab aria-label="Close drawer" onClick={onClose} color="secondary" data-sentry-element="Fab" data-sentry-source-file="Drawer.tsx">
                    <Icon name="close" data-sentry-element="Icon" data-sentry-source-file="Drawer.tsx" />
                </Fab>
            </CloseDrawerButton>
        </>;
}
const StyledDrawer = styled(MUIDrawer)(({
  theme
}) => ({
  "& .MuiDrawer-paper": {
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-track": {
      marginTop: "2px",
      backgroundColor: theme.palette.grey[500]
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: grey[500]
    }
  }
}));
const DrawerHeader = styled("div")(({
  theme
}) => ({
  backgroundColor: theme.palette.grey[500],
  borderBottom: `1px solid ${theme.palette.action.disabled}`,
  maxWidth: 480,
  position: "fixed",
  zIndex: 99999,
  minHeight: 64,
  width: "75vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));
const DrawerContent = styled("div")<{
  header: string;
}>(({
  header,
  theme
}) => ({
  maxWidth: 480,
  minHeight: "calc(100vh - 64px)",
  padding: 0,
  position: "relative",
  width: "75vw",
  zIndex: -1,
  marginTop: header === "true" ? theme.constants.height.header : 0
}));
const CloseDrawerButton = styled(Slide)<{
  position: string;
}>(({
  position,
  theme
}) => ({
  [theme.breakpoints.only("xs")]: {
    [position === "left" ? "right" : "left"]: theme.spacing(2)
  },
  [theme.breakpoints.up("sm")]: {
    [position === "left" ? "left" : "right"]: `calc(497px + ${theme.spacing(3)})`
  },
  position: "fixed",
  top: theme.spacing(2),
  zIndex: 9999,
  "&:focus": {
    boxShadow: "0 0 0 3px #fff"
  }
}));