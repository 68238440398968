import { currentConfig } from "auth"
import NodeCache from "node-cache"

const cache = new NodeCache()

/**
 * Retrieves an access_token from IDP,
 * using client_credentials (client secret) authorization type.
 */
export default async function getServerToken(): Promise<string> {
    const cachedAccessToken = cache.get<string>("access_token")
    // Try returning the cached token
    if (cachedAccessToken) {
        return cachedAccessToken
    }

    /**
     * If no access token was present in the cache, fetch a new one
     * @note Do not need to use refresh tokens, since we are server-side
     * @see https://tools.ietf.org/html/rfc6749#section-4.4.3
     */

    const accessToken = await currentConfig.getServerSideAccessToken()

    // Update the cache with a time-to-live
    cache.set("access_token", accessToken.access_token, accessToken.expires_in)

    return accessToken.access_token
}
