import getServerToken from "data/getServerToken"
import { get } from "lib/http/http"

/**
 * Get list of values for claim type
 * @param userGuid
 * @param type Claim type ("name")
 * @returns List of { key, value }
 */
export async function getClaim(userGuid: string, type: string) {
    const authToken = await getServerToken()
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/customer/${userGuid}/claim?claimType=${type}`
    const { data } = await get<{ key: string; value: string }[]>(url, authToken)
    return data
}

export interface ISubscription {
    id: number
    start: string
    stop: string | null
    product: {
        id: number
        name: string
        applicationId: number
    }
}

/**
 * Get all subscriptions for a user
 * @param userGuid
 * @returns List of subscriptions
 */
export async function getSubscriptions(userGuid: string) {
    const authToken = await getServerToken()
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/customer/${userGuid}/subscription`
    const { data } = await get<ISubscription[]>(url, authToken)
    return data
}
