import useUser from "hooks/useUser"
import { useEffect, useMemo, useState } from "react"

import { IPage } from "data/contentData/api/content"
import { useGetOrganisation } from "data/customerManager/hooks/organisation.hooks"
import { isAuthorized } from "auth/authorization"

type AuthType = "user" | "organisation" | "none"

interface IAccessInfo {
    isLoading: boolean
    authenticated: boolean
    roles: string[]
    authorized: boolean
    authType: AuthType
}

/**
 * Get access info for page
 * @param page page to check access for
 * @returns object describing access info
 */
/**
 * Get access info for page
 * @param {IPage} page - Page to check access for
 * @returns {IAccessInfo} Object describing access info
 */
export default function useGetAccessInfo(page: IPage): IAccessInfo {
    const [authType, setAuthType] = useState<AuthType>("none")
    const [authorized, setAuthorized] = useState(false)

    const organisation = useGetOrganisation()
    const user = useUser()

    const isLoading = user.isLoading || organisation.isLoading
    const authenticated = !isLoading && authType !== "none"
    const roles = useMemo(
        () => user.user?.roles || organisation.roles || [],
        [user.user?.roles, organisation.roles]
    )

    useEffect(() => {
        if (isLoading) {
            return
        }

        let _authType: AuthType = "none"

        if (user.authenticated) {
            _authType = "user"
        }

        if (organisation.authenticated) {
            _authType = "organisation"
        }

        setAuthType(_authType)
    }, [user, organisation, isLoading])

    useEffect(() => {
        if (isLoading) {
            return
        }

        const _authorized = isAuthorized(roles, page?.hasReadAccess)

        setAuthorized(_authorized)
    }, [isLoading, page?.hasReadAccess, roles])

    return {
        isLoading,
        authenticated,
        roles,
        authorized,
        authType
    }
}
