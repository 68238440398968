import { PortableTextTypeComponentProps } from "@portabletext/react";
import { useGallery } from "components/Gallery";
import Image, { IContentImage } from "components/PortableText/Types/Image";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";
function alignFloat(align: "none" | "pull-left" | "pull-right" | undefined) {
  if (align === "pull-left") {
    return "left";
  }
  if (align === "pull-right") {
    return "right";
  }
  return "none";
}
function getMaxWidth(size: "small" | "medium" | "large" | undefined) {
  if (size === "small") {
    return "33%";
  }
  if (size === "medium") {
    return "50%";
  }
  if (size === "large") {
    return "100%";
  }
  return "100%";
}
export default function ImageWithGalleryLink(props: PortableTextTypeComponentProps<IContentImage>) {
  const {
    setCurrent
  } = useGallery();
  const float = alignFloat(props.value.align);
  const maxWidth = getMaxWidth(props.value.size);
  const handleClick = () => setCurrent(props.value.index ?? 0);
  if (props.value.noZoom) {
    return <StyledImageWrapper float={float} maxwidth={maxWidth}>
                <Image alt={props.value.alt} {...props} />
            </StyledImageWrapper>;
  }
  return <StyledButtonBase disableRipple onClick={handleClick} float={float} maxwidth={maxWidth} data-sentry-element="StyledButtonBase" data-sentry-component="ImageWithGalleryLink" data-sentry-source-file="ImageWithGalleryLink.tsx">
            <Image alt={props.value.alt} {...props} button data-sentry-element="Image" data-sentry-source-file="ImageWithGalleryLink.tsx" />
        </StyledButtonBase>;
}
const StyledButtonBase = styled(ButtonBase)<{
  float: "right" | "left" | "none";
  maxwidth: string;
}>(({
  theme,
  float,
  maxwidth
}) => ({
  display: "flex",
  float: float,
  maxWidth: maxwidth,
  margin: theme.spacing(),
  textAlign: "left",
  "&:focus": {
    outline: "none",
    boxShadow: `0 0 0 3px ${theme.palette.secondary?.light}`
  }
}));
const StyledImageWrapper = styled("div")<{
  float: "right" | "left" | "none";
  maxwidth: string;
}>(({
  float,
  maxwidth
}) => ({
  float: float,
  maxWidth: maxwidth
}));