import { DefaultTab } from "components/ProfilePopover/User/DefaultTab";
import { NotificationsTab } from "components/ProfilePopover/User/NotificationsTab";
import { SettingsTab } from "components/ProfilePopover/User/SettingsTab";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import UserDetailsTab from "./UserDetailsTab";
export type ProfileTabType = "default" | "settings" | "notifications" | "confirmUserDetails";
export default function ProfileUser() {
  const [tabId, setTabId] = useState<ProfileTabType>("default");
  const handleTabChange = (nextTab: ProfileTabType = "default") => () => {
    setTabId(nextTab);
  };
  switch (tabId) {
    case "settings":
      return <StyledWrapper>
                    <SettingsTab changeTab={handleTabChange} />
                </StyledWrapper>;
      break;
    case "notifications":
      return <StyledWrapper>
                    <NotificationsTab changeTab={handleTabChange} />
                </StyledWrapper>;
      break;
    case "confirmUserDetails":
      return <StyledWrapper>
                    <UserDetailsTab changeTab={handleTabChange} />
                </StyledWrapper>;
      break;
    default:
      return <StyledWrapper>
                    <DefaultTab changeTab={handleTabChange} />
                </StyledWrapper>;
      break;
  }
}
const StyledWrapper = styled("div")(({
  theme
}) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  gap: theme.spacing(3)
}));
export const StyledDefaultHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  backgroundColor: grey[200],
  padding: "16px",
  margin: "-24px -24px 0 -24px",
  fontWeight: 700
}));