import { useDetectOnPageSearch } from "hooks/useDetectOnPageSearch"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"

import useMediaQuery from "@mui/material/useMediaQuery"
import useHashChange from "hooks/useHashChange"

/**
 * Hook to handle the open state of an expandable component
 * @param id Id to check if the current hash is the same as the id
 * @param collapsed Default value for the open state
 * @param onChange Handler for the open state
 * @returns Handler for the open state and the open state
 */
export function useSemiControlledExpand(
    id: string | undefined,
    collapsed: boolean | undefined,
    onChange?: () => void
) {
    const isPrinting = useMediaQuery("print")
    const router = useRouter()

    const [open, setOpen] = useState(
        Boolean(isPrinting || !collapsed || (id && router.asPath.includes(id)))
    )

    useHashChange(hash => {
        if (!id || !hash.startsWith(id)) return
        setOpen(true)
    })

    const isOnPageSearching = useDetectOnPageSearch()

    useEffect(() => {
        if (isPrinting || Boolean(isOnPageSearching)) {
            setOpen(true)
        }
    }, [isPrinting, isOnPageSearching])

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const defaultOnChange = () => setOpen(open => !open)
    return { onChange: onChange ?? defaultOnChange, open }
}
