import { Icon } from "components/Icon";
import { t } from "locales";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
export function LogoutButton() {
  const handleLogout = async () => {
    window.location.href = "/api/auth/federated-logout";
  };
  return <StyledLogoutButton startIcon={<Icon name="logout" />} variant="outlined" onClick={handleLogout} data-sentry-element="StyledLogoutButton" data-sentry-component="LogoutButton" data-sentry-source-file="LogoutButton.tsx">
            {t.logout}
        </StyledLogoutButton>;
}
const StyledLogoutButton = styled(Button)(() => ({
  alignSelf: "flex-start"
}));