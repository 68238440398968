import { Drawer } from "components/Drawer/Drawer";
import NodeDrawerItem from "components/Drawer/NodeDrawerItem";
import { Icon } from "components/Icon";
import { PropsWithChildren, ReactElement, useCallback, useEffect, useState } from "react";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
export type Node<T = Record<string, unknown>> = {
  icon?: string;
  name?: string;
  url: string;
  shortcut?: string;
  active?: boolean;
  disabled?: boolean;
  dense?: boolean;
  nodes?: Node<T>[];
} & T;
interface DrawerProps<T extends Record<string, unknown> = Record<string, unknown>> {
  node: Node<T>;
  open: boolean;
  position?: "left" | "right";
  onClose(): void;
  linksAtDepth?: number;
  renderItem?(item: Node<T>): ReactElement;
  noItem?: ReactElement | null;
}
export default function NodeDrawer<T extends Record<string, unknown> = Record<string, unknown>>(props: PropsWithChildren<DrawerProps<T>>) {
  const {
    open,
    node,
    onClose,
    position = "left",
    children,
    linksAtDepth = 0,
    renderItem,
    noItem = null
  } = props;
  const [{
    activeNode,
    parents
  }, setState] = useState<{
    parents: Node[];
    activeNode?: Node;
  }>({
    activeNode: {
      ...node,
      nodes: [...(node.nodes ?? [])]
    },
    parents: []
  });
  useEffect(() => {
    setState({
      activeNode: {
        ...node,
        nodes: [...(node.nodes ?? [])]
      },
      parents: []
    });
  }, [node]);
  const handleUp = useCallback(() => {
    setState(prevState => ({
      activeNode: prevState.parents[0],
      parents: prevState.parents.slice(1)
    }));
  }, []);
  const handleDown = useCallback((n: Node) => (e: Event) => {
    e.stopPropagation();
    setState(prevState => ({
      activeNode: n,
      parents: prevState.activeNode ? [prevState.activeNode, ...prevState.parents] : prevState.parents
    }));
  }, []);
  return <Drawer header={activeNode?.name ? <MenuItem component="div" disabled={!parents.length} onClick={handleUp}>
                        {parents.length ? <Icon name="arrowBack" /> : null}
                        <Typography width={"100%"} align="center" color="inherit" variant="subtitle2">
                            {activeNode?.name}
                        </Typography>
                    </MenuItem> : null} position={position} open={open} onClose={onClose} data-sentry-element="Drawer" data-sentry-component="NodeDrawer" data-sentry-source-file="NodeDrawer.tsx">
            {node.nodes?.length || activeNode?.nodes?.length ? <>
                    {children}
                    <List disablePadding>
                        {renderItem ? node.nodes?.map(renderItem) : activeNode?.nodes?.map(n => <NodeDrawerItem key={n.url} depth={parents.length + 1} direction={position} linksAtDepth={linksAtDepth} node={n} onClose={onClose} onShowChildren={handleDown(n)} />)}
                    </List>
                </> : noItem}
        </Drawer>;
}